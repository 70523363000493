<template>
  <div class="container-fluid p-0">
    <div v-if="loading" id="loader" class="loader"></div>

    <div class="grad-bg">
      <img
        :src="userTheme === 'dark-theme' ? darkImageHome : lightImageHome"
        draggable="false"
        class="blob-grad"
      />
    </div>

    <Navbar />
    <div class="container py-5">
      <div class="row" :class="{ ' justify-content-between': isDesktop }">
        <div class="col-12 col-md-12 col-lg-5">
          <h1 class="big-title" :class="{ 'mt-5': !isMobile }">APPLY<br /></h1>
          <h2>AS AN EVENT CREATOR</h2>
          <div class="mt-5" v-if="isSuccess !== ''">
            <h3 class="mb-4">{{ isSuccess }}</h3>
            <a href="https://admin.mego.tickets" target="_blank" class="btn mt-"
              >ADMIN PANEL</a
            >
          </div>
        </div>
        <div
          class="col-12 col-md-12 col-lg-5"
          :class="{ 'mt-5': isDesktop, 'mt-4': !isDesktop }"
        >
          <!-- USERN NAME -->
          <div class="group">
            <input required="" type="text" class="input" v-model="name" />
            <span class="highlight"></span>
            <span class="bar"></span>
            <label>Name*</label>
          </div>

          <!-- EMAIL -->
          <div class="mt-4">
            <div class="group">
              <input
                @input="checkEmail()"
                required=""
                type="text"
                class="input"
                v-model="email"
              />
              <span class="highlight"></span>
              <span class="bar"></span>
              <label>E-mail*</label>
            </div>
            <p class="small mt-1" v-if="!emailIsValid && email?.length > 5">
              make sure you have entered the email <br />
              correctly
            </p>
          </div>

          <!-- ETH -->
          <div class="group mt-4">
            <input required="" type="text" class="input" v-model="address" />
            <span class="highlight"></span>
            <span class="bar"></span>
            <label>ETH Address*</label>
          </div>

          <!-- DISCORD -->
          <div class="group mt-4">
            <input required="" type="text" class="input" v-model="discord" />
            <span class="highlight"></span>
            <span class="bar"></span>
            <label>Discord</label>
          </div>

          <!-- TELEGRAM -->
          <div class="group mt-4">
            <input required="" type="text" class="input" v-model="telegram" />
            <span class="highlight"></span>
            <span class="bar"></span>
            <label>Telegram</label>
          </div>

          <!-- TWITTER -->
          <div class="group mt-4">
            <input required="" type="text" class="input" v-model="twitter" />
            <span class="highlight"></span>
            <span class="bar"></span>
            <label>Twitter</label>
          </div>

          <div class="mt-5">
            <button
              v-if="!isWorking"
              :disabled="
                !emailIsValid || name?.length < 3 || address?.length < 10
              "
              @click="requestApplication()"
              class="btn mt-3"
            >
              APPLY
            </button>
            <button v-if="isWorking" class="btn mt-3" style="cursor: default">
              {{ workingMessage }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import checkViewport from "../mixins/checkViewport";
import axios from "axios";

export default {
  name: "ApplyForm",
  mixins: [checkViewport],
  components: {
    Navbar,
  },

  data() {
    return {
      name: "",
      email: "",
      address: "",
      discord: "",
      twitter: "",
      telegram: "",
      referralCode: "",

      isWorking: false,
      workingMessage: "",
      isSuccess: "",
      endpoint: "https://tickets-api.mego.tools",

      // CHEK INPUT EMAIL
      emailIsValid: false,
      // UI
      loading: false,
      userTheme: "dark-theme",
      darkImageHome: "/blob-grad-home-comp.png",
      lightImageHome: "/blob-grad-home-light.png",
    };
  },
  mounted() {
    const initUserTheme = this.getTheme() || this.getMediaPreference();
    this.setTheme(initUserTheme);

    // CHECK REFERRAL
    if (window.location.href.split("?referral=")[1] !== undefined) {
      this.referralCode = window.location.href.split("?referral=")[1];
      console.log("Found referral", this.referralCode);
    }
  },

  methods: {
    //CHECK E-MAIL
    checkEmail() {
      const app = this;
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.emailIsValid = emailRegex.test(app.email);
    },

    // APPLY FROM
    async requestApplication() {
      const app = this;
      console.log("Start applying");
      if (!app.isWorking) {
        app.isWorking = true;
        // LOWERCASE EMAIL
        app.email = app.email.toLowerCase();
        app.workingMessage = "Loading, please wait...";
        if (app.referralCode !== "") {
          const application = await axios.post(
            app.endpoint + "/users/admins/create",
            {
              name: app.name,
              email: app.email,
              address: app.address,
              telegram: app.telegram,
              twitter: app.twitter,
              discord: app.discord,
              referral: app.referralCode,
            }
          );
          console.log("RESPONSE API", application.data);
          if (!application.data.error) {
            app.isSuccess = application.data.message;
            app.isWorking = false;
            app.workingMessage = "";
            app.name = "";
            app.email = "";
            app.address = "";
            app.discord = "";
            app.twitter = "";
            app.telegram = "";
          } else {
            alert(application.data.message);
            app.isWorking = false;
            app.workingMessage = "";
            app.isSuccess = false;
          }
        } else {
          const application = await axios.post(
            app.endpoint + "/users/admins/create",
            {
              name: app.name,
              email: app.email,
              address: app.address,
              telegram: app.telegram,
              twitter: app.twitter,
              discord: app.discord,
            }
          );
          console.log("RESPONSE API", application.data);
          if (!application.data.error) {
            app.isSuccess = application.data.message;
            app.isWorking = false;
            app.workingMessage = "";
            app.name = "";
            app.email = "";
            app.address = "";
            app.discord = "";
            app.twitter = "";
            app.telegram = "";
          } else {
            alert(application.data.message);
            app.isWorking = false;
            app.workingMessage = "";
            app.isSuccess = false;
          }
        }
      }
    },

    /* >>>> THEME SETTINGS <<<< */
    setTheme(theme) {
      localStorage.setItem("user-theme", theme);
      this.userTheme = theme;
      document.documentElement.className = theme;
    },
    getTheme() {
      return localStorage.getItem("user-theme");
    },
    getMediaPreference() {
      const hasDarkPreference = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      if (hasDarkPreference) {
        return "dark-theme";
      } else {
        return "dark-theme";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.grad-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -2;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: auto;
  }
}
.big-title {
  font-size: 5.25rem;
  font-weight: 400;
  top: 2.5rem;
  left: 0;
  line-height: 0.9;
}

.group {
  position: relative;
}

.input {
  font-size: 1rem;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  max-width: 300px;
  border: none;
  border-bottom: 1px solid var(--accentColor);
  background: transparent;
  color: var(--text);
}

.input:focus {
  outline: none;
}

label {
  color: var(--accentColor);
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.input:focus ~ label,
.input:valid ~ label {
  top: -10px;
  font-size: 14px;
  color: var(--text);
}

.bar {
  position: relative;
  display: block;
  width: 100%;
  max-width: 300px;
}

.bar:before,
.bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: var(--text);
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.bar:before {
  left: 50%;
}

.bar:after {
  right: 50%;
}

.input:focus ~ .bar:before,
.input:focus ~ .bar:after {
  width: 50%;
}

.highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

.input:focus ~ .highlight {
  animation: inputHighlighter 0.3s ease;
}

@keyframes inputHighlighter {
  from {
    background: transparent;
  }

  to {
    width: 0;
    background: transparent;
  }
}

p.small {
  color: #ccc;
}

button {
  &:disabled {
    background: var(--accentColor);
    opacity: 0.3;
  }
}

@media screen and (max-width: 768px) {
  .big-title {
    font-size: 3.25rem;
    font-weight: 500;
    top: 2.5rem;
    left: 0;
    line-height: 0.9;
  }
}
</style>
